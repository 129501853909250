import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/AuthStore';
import { httpGet, httpPost } from '@/services/http';
import { toast } from 'vue3-toastify';
import { indexedDBService } from '@/services/indexedDBService';
import global from '@/global';

export const useWebSocketStore = defineStore('websocket', {
    state: () => {
        return {
            connected: false,
            socket: null,
            socket_id: null,
            reconnectTimer: null,
            pingTimer: null,
            // Add long polling states
            isPolling: false,
            pollingTimer: null,
            // Add force polling option
            forceLongPolling: false
        };
    },

    actions: {
        // Add long polling methods
        async startLongPolling() {
            if (this.isPolling) return;

            this.isPolling = true;
            await this.pollNotifications();
        },

        async pollNotifications() {
            if (!this.isPolling) return;

            try {
                console.log('Polling for notifications...');
                const lastUpdateTime = await indexedDBService.getLastNotificationUpdateTime();
                // Prepare the query parameter
                const updatedAt = lastUpdateTime ? lastUpdateTime : null;
                const queryParams = updatedAt ? `?updated_at=${updatedAt}` : '';
                const response = await httpGet(`/api/v1/users/notifications/poll${queryParams}`);

                const data = await response.data.data;

                if (response && response.data && response.data.data) {  // Changed to match API response structure
                    await indexedDBService.insertNotifications(response);  // Pass the whole response

                    // Handle notifications if any exist
                    if (response.data.data.length > 0) {
                        response.data.data.forEach(notification => {
                            this.handleUserNotification(notification);
                        });
                    }
                }

                // Continue polling
                this.pollingTimer = setTimeout(() => {
                    this.pollNotifications();
                }, 3000); // Poll every 3 seconds

            } catch (error) {
                console.error('Polling error:', error);
                this.pollingTimer = setTimeout(() => {
                    this.pollNotifications();
                }, 5000); // Retry after 5 seconds on error
            }
        },

        stopLongPolling() {
            this.isPolling = false;
            if (this.pollingTimer) {
                clearTimeout(this.pollingTimer);
                this.pollingTimer = null;
            }
        },


        connect() {
            // Don't connect if force polling is enabled
            if (this.forceLongPolling) {
                this.startLongPolling();
                return;
            }

            if (this.connected || this.socket?.readyState === WebSocket.CONNECTING) {
                return;
            }

            const wsUrl = 'wss://api.rectrixlink.com:6001/app/app-key';
            this.socket = new WebSocket(wsUrl);

            this.socket.onopen = () => {
                console.log('Websocket connected');
                this.connected = true;
                this.reconnectTimer = null;
                this.sendPing(); // Start the ping process once connected
                // Only stop polling if not forced
                if (!this.forceLongPolling) {
                    this.stopLongPolling();
                }
            };

            this.socket.onerror = (error) => {
                this.connected = false;
                console.error('Websocket error:', error);
            };

            this.socket.onmessage = (event) => {
                const dataJSON = JSON.parse(event.data);
                console.log('Websocket message:', dataJSON);
                this.handleWebSocketEvent(dataJSON);
            };

            this.socket.onclose = () => {
                this.connected = false;
                console.log('Websocket closed');
                this.reconnect();
            };
        },

        reconnect() {
            if (this.reconnectTimer) {
                clearTimeout(this.reconnectTimer);
            }
            this.reconnectTimer = setTimeout(() => {
                this.fallback();
            }, 10000);
        },

        fallback() {
            const authStore = useAuthStore();

            if (authStore.isLogin) {
                if (this.forceLongPolling) {
                    if (!this.isPolling) {
                        console.log('Starting long polling (forced mode)...');
                        this.startLongPolling();
                    }
                } else if (!this.connected) {
                    console.log('Attempting to reconnect websocket...');
                    this.connect();

                    if (!this.isPolling) {
                        console.log('Starting long polling as fallback...');
                        this.startLongPolling();
                    }
                }
            }
        },

        async subscribeToChannel() {
            if (!this.socket || !this.socket_id) return;

            const authStore = useAuthStore();
            const websocketRequestEntity = {
                'socket_id': this.socket_id,
                'channel_name': `private-users.${authStore.profile.id}`, // Modify with your context
            };

            try {
                const result = await httpPost('/broadcasting/auth', websocketRequestEntity);
                console.log(result.data.auth);
                this.socket.send(JSON.stringify({
                    event: 'pusher:subscribe',
                    data: {
                        auth: result.data.auth,
                        channel: websocketRequestEntity['channel_name'],
                    },
                }));
            } catch (error) {
                console.error('Error:', error);
            }
        },

        sendPing() {
            if (this.pingTimer) {
                clearTimeout(this.pingTimer);
            }

            if (this.socket && this.connected) {
                console.log('Sending Ping...');
                this.socket.send(JSON.stringify({ event: 'pusher:ping', data: {} }));
            }

            this.pingTimer = setTimeout(() => {
                const authStore = useAuthStore();

                if (authStore.isLogin) {
                    if (!this.connected) {
                        console.log('Attempting to reconnect...');
                        this.connect();
                    } else {
                        this.sendPing();
                    }
                }
            }, 10000);
        },

        disconnect() {
            if (this.reconnectTimer) {
                clearTimeout(this.reconnectTimer);
            }
            if (this.pingTimer) {
                clearTimeout(this.pingTimer);
            }
            if (this.socket) {
                this.socket.close();
            }
            this.socket = null;
            this.connected = false;
            this.stopLongPolling();
            console.log('Websocket disconnected');
        },

        handleWebSocketEvent(dataJSON) {
            switch (dataJSON.event) {
                case 'pusher:connection_established':
                    console.log('Connection established');
                    this.socket_id = JSON.parse(dataJSON.data).socket_id;
                    this.subscribeToChannel();
                    break;
                case 'pusher_internal:subscription_succeeded':
                    console.log('Subscription Succeeded');
                    break;
                case 'pusher:subscription_error':
                    console.error('Subscription Error:', dataJSON);
                    break;
                case 'pusher:pong':
                    console.log('Connection Active');
                    break;
                case 'StreamOffer':
                    this.handleStreamOffer(dataJSON.data);
                    break;
                case 'StreamAnswer':
                    this.handleStreamAnswer(dataJSON.data);
                    break;
                case 'IceCandidate':
                    this.handleIceCandidate(dataJSON.data);
                    break;
                case 'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated':
                    this.handleUserNotification(JSON.parse(dataJSON.data));
                    break;
                default:
                    console.log('Unhandled event:', dataJSON.event);
            }
        },

        handleStreamOffer(data) {
            console.log('StreamOffer Event:', data);
        },

        handleStreamAnswer(data) {
            console.log('StreamAnswer Event:', data);
        },

        handleIceCandidate(data) {
            console.log('IceCandidate Event:', data);
        },

        handleUserNotification(data) {
            const title = data.title;
            const message = data.message;
            // Notiifcation is nullable
            const notificationType = data.notification_type;
            const notification = data.notification;
            const is_log = data.is_log;
            const starts_at = data.starts_at;
            const expires_at = data.expires_at;

            const authStore = useAuthStore();


            if (notificationType) {
                console.log('Notification:', data);
                if (notificationType === 'account_verification_request') {
                    indexedDBService.getRequestsData();
                }
            }

            global.masterFetch();

            if (!is_log) {
                toast.info(message, {
                    onClick: () => {
                        // if (jobSlug === 'document-request') {
                        //     this.$router.push(`/requests/${relevantId}`);
                        // }
                    }
                });
            }

            console.log('Notification Received');
        }
    }
});